body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #eee8df;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-margin {
    margin: 0 !important;
}

.home {
    height: 100vh;
    width: 100vw;
    background: rgb(42, 36, 133);
    background: linear-gradient(143deg, rgba(42, 36, 133, 0.8631652490097601) 0%, rgba(37, 37, 195, 0.944397742006959) 43%, rgba(21, 200, 236, 1) 96%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-header img {
    height: 42px;
}

.home-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-header span {
    font-size: 42px;
    font-weight: 400;
    margin-left: 0.5rem;
}

.home-intro {
    margin: 1.5rem 0 1rem 0;
    font-size: 18px;
    font-weight: 500;
}

.navbar {
    color: white !important;
    background: rgb(42, 36, 133) !important;
    background: linear-gradient(143deg, rgba(42, 36, 133, 1) 0%, rgba(37, 37, 195, 1) 43%, rgba(21, 200, 236, 1) 96%) !important;
}

.navbar-title {
    margin-left: 0.5rem;
}

.navbar-dropdown {
    font-size: 14px;
}

.project-content {
    padding-top: 6rem;
}

.people-coming-list {
    background: #eee8df !important;
}

.notification {
    position: -webkit-sticky;
    position: sticky;
    top: 6rem;
}

.attendee {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.eventInfo-head {
    height: 56vmin;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 6vmin 8vmin;
}

.centerElement {
    display: flex !important;
    align-items: center !important;
}

.user-info-header {
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: 1rem;
}

.user-info-header-detail {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
}

.user-more-info-header, .user-photos-header, .user-events-header {
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: 1rem;
    align-items: center;
}

.user-more-info-content {
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: 0.5rem;
    text-transform: capitalize;
}

.user-event{
    max-width: 180px !important;
    color: black;
}

.person {
    max-width: 170px !important;
    color: black;
    height: 100%;
}

.user-event-title, .person-title {
    text-align: center;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.person-title {
    text-align: center;
}

.user-event-date, .person-address {
    font-weight: bold;
    opacity: 0.3;
    text-align: center;
}

.user-event-list{
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-gap: 1rem;
}

.following-list, .followers-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 170px);
    grid-gap: 1rem;
}

.autocomplete-dropdown-container {
    border: 1px solid black;
    position: absolute;
    width: 100%;
    z-index: 99;
}

.autocomplete-dropdown-container span {
    font-size: 16px;
    padding-top: 20px !important;
}

.react-datepicker-wrapper {
    width: 100%;
    margin-bottom: 1rem;
}

.react-datepicker-popper {
    z-index: 999;
    margin-top: 20px;
}

.react-datepicker__input-container {
    width: 100%;
}

.setting-header {
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 0;
}

.react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
    background-color: #e9e9e9;
}

.circle-marker {
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
    border: 2px solid #FFF;
    background: #1275E8;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 100%;
    z-index: 999999;
}

.authenticate {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.authenticate-card{
    width: 360px !important;
}

.userMoreInfo-field{
    margin-top: 0.5rem;
}

.notification-img{
    display: flex !important;
    align-items: center !important;
}



